<template>
  <div style="position: relative" :style="{width: `${edge * 1.31}px`,height: `${edge}px`}">
    <div class="cd">
      <div style="margin: auto;background-color: white;height: 40%;width: 40%;border-radius: 50%"></div>
    </div>
    <el-image lazy :src="src" :style="{width: `${edge}px`,height: `${edge}px`}"></el-image>
    <div v-if="isShowDraftLabel" style="position: absolute;left: 12px;top: 12px;width: 64px;height: 26px;background: #ffedbc;border-radius: 4px;color: #a5773f;font-size: 12px;line-height: 26px;text-align: center">草稿</div>
  </div>
</template>

<script>
export default {
  name: "HiAlbumCover",
  props: {
    src: String,
    edge: {
      type: Number,
      default: 140
    },
    isShowDraftLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.cd{
  background: linear-gradient(228deg,#000000, #5e5e5e, #000000);
  border-radius: 50%;
  width: 66%;
  height: 85%;
  position: absolute;
  right: 0;
  top: 7.5%;
  display: flex;
}
</style>
