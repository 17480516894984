import request from '@/utils/request'
/**
 * @description 专辑详情
 * @param {string} params.albumId albumId
 */
export const albumDetail = (data, params) => request({
    url: `/api/song/albumDetail`,
    method: 'post',
    data, params,
})

/**
 * @description 专辑列表
 * @param {object} data.queryRequest queryRequest
 * @param {string} data.queryRequest.endTime 上传时间(结束)
 * @param {string} data.queryRequest.issueStatus 发行状态:0未发行 1已发行 (可多选)
 * @param {string} data.queryRequest.name 专辑名or歌曲名
 * @param {integer} data.queryRequest.pageNum 页码
 * @param {integer} data.queryRequest.pageSize 每页数量
 * @param {string} data.queryRequest.startTime 上传时间(开始)
 * @param {string} data.queryRequest.status 授权状态:0待审核;1审核成功(已授权);2审核失败;4草稿 (可多选,未授权传入 0,2)
 */
export const albumList = (data) => request({
    url: `/api/song/albumList`,
    method: 'post',
    data,
})

/**
 * @description 专辑上传
 * @param {object} data.albumForm albumForm
 * @param {string} data.albumForm.albumName 专辑名称
 * @param {string} data.albumForm.albumUrl 专辑封面url
 * @param {string} data.albumForm.desp 专辑介绍
 * @param {string} data.albumForm.id 专辑id
 */
export const albumSubmit = (data) => request({
    url: `/api/song/albumSubmit`,
    method: 'post',
    data,
})

/**
 * @description 删除专辑
 * @param {string} params.albumId albumId
 */
export const delAlbum = (data, params) => request({
    url: `/api/song/delAlbum`,
    method: 'post',
    data, params,
})

/**
 * @description 删除歌曲
 * @param {string} params.songId songId
 */
export const delSong = (data, params) => request({
    url: `/api/song/delSong`,
    method: 'post',
    data, params,
})

/**
 * @description 歌曲详情数据---旧
 * @param {string} params.X-EFRESH-SECRET X-EFRESH-SECRET
 * @param {string} params.X-EFRESH-SESSION X-EFRESH-SESSION
 * @param {integer} params.songId songId
 */
export const detailSong = (params) => request({
    url: `/api/song/detail`,
    method: 'get',
    params,
})

/**
 * @description 附件上传
 * @param {file} data.file 附件
 */
export const fileUploadSong = (data) => request({
    url: `/api/song/fileUpload`,
    method: 'post',
    data,
})

/**
 * @description 作品首页汇总数量数据
 */
export const indexSong = (data) => request({
    url: `/api/song/index`,
    method: 'post',
    data,
})

/**
 * @description 作品发行记录列表
 * @param {object} data.issueRecordRequest issueRecordRequest
 * @param {integer} data.issueRecordRequest.pageNum 页码
 * @param {integer} data.issueRecordRequest.pageSize 每页数量
 * @param {string} data.issueRecordRequest.songId 歌曲id
 */
export const issueRecord = (data) => request({
    url: `/api/song/issueRecord`,
    method: 'post',
    data,
})

/**
 * @description 作品列表
 * @param {object} data.queryRequest queryRequest
 * @param {string} data.queryRequest.endTime 上传时间(结束)
 * @param {string} data.queryRequest.issueStatus 发行状态:0未发行 1已发行 (可多选)
 * @param {string} data.queryRequest.name 专辑名or歌曲名
 * @param {integer} data.queryRequest.pageNum 页码
 * @param {integer} data.queryRequest.pageSize 每页数量
 * @param {string} data.queryRequest.startTime 上传时间(开始)
 * @param {string} data.queryRequest.status 授权状态:0待审核;1审核成功(已授权);2审核失败;4草稿 (可多选,未授权传入 0,2)
 */
export const musicianSongList = (data) => request({
    url: `/api/song/musicianSongList`,
    method: 'post',
    data,
})

/**
 * @description 作品详情
 * @param {string} params.songId songId
 */
export const songDetail = (data, params) => request({
    url: `/api/song/songDetail`,
    method: 'post',
    data, params,
})

/**
 * @description 作品表现列表
 * @param {{name: string, pageSize: number, sort: number, pageNum: number, order: number}} data songHistoryRequest
 * @param {string} data.name 作品名/专辑名
 * @param {integer} data.order 0默认;1倒排(默认0)
 * @param {integer} data.pageNum 页码
 * @param {integer} data.pageSize 每页数量
 * @param {integer} data.sort 排序: 0播放指数优先; 1下载指数优先; 2收藏指数优先 3最新
 */
export const songHistory = (data) => request({
    url: `/api/song/songHistory`,
    method: 'post',
    data,
})

/**
 * @description 专辑下的作品列表----web使用
 * @param {string} params.albumId albumId
 */
export const songList = (data, params) => request({
    url: `/api/song/songList`,
    method: 'post',
    data, params,
})

/**
 * @description 作品提交
 * @param {object} data.songRequest songRequest
 * @param {string} data.songRequest.albumId 专辑id
 * @param {array} data.songRequest.songFormList 上传歌曲列表
 * @param {integer} data.songRequest.status 提交状态: 0保存草稿箱;1直接提交
 */
export const songSubmit = (data) => request({
    url: `/api/song/songSubmit`,
    method: 'post',
    data,
})

/**
 * @description 专辑提交审核
 * @param {string} params.albumId albumId
 */
export const submitExamine = (data, params) => request({
    url: `/api/song/submitExamine`,
    method: 'post',
    data, params,
})

export const songIndex = () =>request({
    url:'/api/song/index',
    method: 'post',
})
export  const authorizationSubmit =(data) =>request({
    url:'/api/song/authorizationSubmit',
    method:'post',
    data
})
export const activityList =(data) =>request({
    url:'/api/activity/list',
    method:'post',
    data
})
  export const songDelSong = (data,params) =>request({
      url:'/api/song/delSong',
      method:'post',
      data,params
  })
