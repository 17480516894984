<template>
  <div style="font-size: 14px;display: flex;align-items: center;color: #333333">
    <div class="symbol" :class="{disabled: isFirstPage}" @click="isFirstPage ? undefined : $emit('update:page', page - 1)">&lsaquo;</div>
    {{ page }}/{{ totalPage }}
    <div class="symbol" :class="{disabled: isLastPage}" @click="isLastPage ? undefined : $emit('update:page', page + 1)">&rsaquo;</div>
  </div>
</template>

<script>
export default {
  name: "HiSimplePagination",
  props: {
    total: Number,
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 5
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    isFirstPage(){
      return this.page === 1
    },
    isLastPage(){
      return this.page === this.totalPage
    }
  }
}
</script>

<style scoped>
.symbol{
  padding: 5px 13px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}
.disabled{
  color: #999999;
}

</style>
