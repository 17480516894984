<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item to="/song-manage">歌曲管理</el-breadcrumb-item>
      <el-breadcrumb-item>专辑详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider></el-divider>

    <div v-if="album.status !== 4" style="color: #333333;font-size: 20px;font-weight: 500;display: flex;align-items: center;font-family: 'PingFang SC-Medium'">
      <el-image style="width: 24px;height: 24px;margin-right: 12px;" :src="[albumAuthorizingImage, albumAuthorizedImage, albumUnauthorizedImage][album.status || 0]"></el-image>
      {{ ['专辑审核中', '专辑已授权', '专辑审核失败'][album.status] }}
    </div>

    <p v-if="album.status === 0" style="color: #333333;font-size: 14px">
      我们将在7天内完成作品审核，请耐心等待
    </p>
    <p v-if="album.status === 2" style="color: #FF477E;font-size: 14px">
      原因: {{ album.examineDesp }}
    </p>

    <div style="padding: 25px;background-color: #F8F8F8;display: flex;margin-top: 24px">
      <hi-album-cover v-if="album.cover" :src="album.cover" :is-show-draft-label="album.status === 4"></hi-album-cover>
      <div style="padding-left: 30px">
        <div style="color: #333333">{{ album.name }}</div>
        <div style="display: flex;margin-top: 7px">
          <div>
            <div class="description"><span class="label">专辑歌手</span>{{ album.singers }}</div>
            <div class="description"><span class="label">流　　派</span>{{ album.schools || '-' }}</div>
          </div>
          <div>
            <div class="description" v-if="album.issueStatus === 1"><span class="label">首发时间</span>{{ $timestampToDate(album.firstRoundTime) }}</div>
            <div class="description" v-else><span class="label">是否发行</span>否</div>
            <div class="description" v-if="album.issueStatus === 1"><span class="label">语　　种</span>{{ album.language || '-' }}</div>
            <div class="description" v-else><span class="label">预计发行</span>授权成功后，3-12个工作日完成发行</div>
          </div>
          <div>
            <div class="description" v-if="album.issueStatus === 1"><span class="label">是否发行</span>是</div>
          </div>
        </div>

        <div class="description" style="display: flex;margin-right: 0">
          <span class="label">专辑简介</span>
          <div style="display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow: hidden" :title="album.desp">
            {{ album.desp }}
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex;justify-content: space-between;margin-top: 30px">
      <div style="font-size: 20px;color: #333333;font-family: 'PingFang SC'">歌曲详情</div>
      <hi-simple-pagination :page.sync="pageNum" :total="songList.length"></hi-simple-pagination>
    </div>

    <el-table
        :data="songList.slice(pageNum * 5 - 5, Math.min(pageNum * 5, songList.length))"
        style="width: 100%">
      <el-table-column
          type="index"
          :index="(index)=>{ return pageNum * 5 - 4 + index}"
          label="序号">
      </el-table-column>
      <el-table-column
          prop="name"
          v-slot="{row}"
          label="歌曲名称">
        <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" :title="row.name">
          {{ row.name }}
        </div>
      </el-table-column>
      <el-table-column
          v-slot="{row}"
          label="权益">
        {{ row.role ? row.role.join('/') : '-'}}
      </el-table-column>
      <el-table-column
          prop="firstRoundTime"
          :formatter="(_, __, v) => {return $timestampToDate(v)}"
          label="首发时间"
          width="180">
      </el-table-column>
      <el-table-column
          prop="status"
          label="授权状态"
          v-slot="{row}">
        <span v-if="row.status === 0" style="color: #2879FF">授权审核中</span>
        <span v-else-if="row.status === 1" style="color: #666666">已授权</span>
        <span v-else-if="row.status === 2" style="color: #EC7E00">授权失败</span>
        <span v-else>-</span>
      </el-table-column>
      <el-table-column
          v-slot="{row}"
          label="操作">
        <el-link v-if="row.status === 2" type="primary" @click="$router.push('/release/' + id)">修改</el-link>
        <el-link v-else type="primary" @click="$router.push('/song/' + row.id)">详情</el-link>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import albumAuthorizingImage from '@/assets/album-authorizing.png'
import albumAuthorizedImage from '@/assets/album-authorized.png'
import albumUnauthorizedImage from '@/assets/album-unauthorized.png'
import {albumDetail} from "@/api/song"
import HiAlbumCover from "@/views/songManage/components/HiAlbumCover"
import HiSimplePagination from "@/views/songManage/components/HiSimplePagination"
export default {
  name: "Album",
  props: {
    id: {
      type: String
    }
  },
  components: {
    HiAlbumCover,
    HiSimplePagination
  },
  data(){
    return {
      albumAuthorizedImage,
      albumAuthorizingImage,
      albumUnauthorizedImage,

      album: {},
      songList: [],

      pageNum: 1
    }
  },
  mounted() {
    this.getAlbumDetail()
  },
  methods: {
    getAlbumDetail(){
      albumDetail({}, {albumId: this.id}).then(r => {
        this.album = r.data.musicianAlbum
        this.songList = r.data.musicianSongVos
      })
    }
  }
}
</script>

<style scoped lang="scss">
.description{
  font-size: 14px;
  color: #333333;
  margin-right: 60px;
  padding: 5px 0;
  line-height: 22px;
  .label{
    color: #999999;
    margin-right: 16px;
    min-width: 56px;
  }
}
</style>
